@import "../abstract/variables";

.job-row {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 1rem;
  h2 {
    color: #343434;
    margin: 0;
  }
  h3 {
    color: #1297a9;
    font-size: 24px;
    margin-bottom: 0.8rem;
    position: relative;
    z-index: 5;
  }
  h3::before {
    content: "";
    background-color: #a3eaea81;
    width: 11rem;
    height: 50%;
    top: 100%;
    position: absolute;
    z-index: 2;
    border-radius: 20%;
  }
  p {
    color: #707070;
    font-weight: 300;
  }
  .job-accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .job-title-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .accordion-labels-container {
      @media (max-width: ($breakpoint-md)) {
        padding-inline-start: 4.5rem;
        margin-bottom: 1rem;
      }
      .accordion-labels-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .accordion-labels {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0 5px;
          .label-icon {
            margin: 0 5px;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .job-accordion-paragraph {
    padding-inline-start: 4.5rem;
    .left-column {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  .details-container {
    margin-bottom: 1rem;
    padding: 0 4.5rem;
    color: #707070;
    .job-details__list {
      list-style-type: disc;
      padding: 0 1rem;
      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}
