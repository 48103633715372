@import "../abstract/variables";

.installments-section {
  padding: 6rem 0;
  .installments-box {
    width: 85vw;
    max-width: 1440px;
    margin: auto;
    display: flex;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 40px 99px #1515151e;
    border-radius: 10px;

    .box-img {
      @media (max-width: 1200px) {
        display: none;
      }
      display: flex;
      border-radius: 10px;
      flex: 1;
      // width: 50%;
      img {
        object-fit: cover;
        border-radius: 10px;
        width: 100%;
      }
    }
    .box-content {
      display: flex;
      flex-direction: column;
      // width: 50%;
      flex: 1;
      .content-header {
        margin-top: 75px;
        margin-bottom: 50px;
        text-align: center;
        h4 {
          font-size: 21px;
          color: #f37021;
        }
        h5 {
          font-size: 26px;
          color: #000000;
        }
      }
      .content-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 50px 25px;
        border-radius: 10px;
        background: #f6f6f6;
        box-shadow: 0px 40px 99px #1515151e;

        .main-text {
          font-size: 16px;
          text-transform: uppercase;
        }
        .sub-text {
          font-size: 14px;
          color: #707070;
        }
        .verification-code {
          width: 34vw;
          height: 99px;
          background: #ffffff;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #ffffff;
          border-radius: 7px;
          text-align: center;
          font-size: 42px;
          letter-spacing: 20px;
          color: #000000;
          margin: 20px 0;
          @media (max-width: $breakpoint-md) {
            width: 100%;
          }
          @media (max-width: $breakpoint-sm) {
            letter-spacing: 5px;
          }
        }
        .resend-verification {
          text-align: center;
          color: #15abc1;
          margin-top: 10px;
          span {
            text-align: center;
            font-size: 16px;
            color: #15abc1;
          }
        }
        .amnmf-btn {
          border-radius: 2rem;
          margin: 0 0.25rem;
          justify-content: center;
          transition: 0.4s;
          box-shadow: 0px 3px 6px #00000027;
          height: 40px;
          width: fit-content;
          border: 0;
          margin-top: auto;
          &.--filled {
            max-width: 396px;
            width: 39vw;
            background: #e1591f;
            &:hover {
              background-color: #f37021;
              color: #fff;
            }
          }
        }

        .installment-info,
        .installment-paid,
        .no-installments {
          width: 100%;
        }
        .installment-info {
          background: #ffffff;
          border-radius: 8px;
          padding: 2rem;
          margin-bottom: 30px;
          .installment-date,
          .installment-user,
          .installment-amount {
            padding: 20px 40px;
            text-align: center;
            align-items: center;
          }
          .installment-date {
            border-bottom: 1px solid #e0e0e0;
            h5 {
              font-size: 22px;
              color: #333333;
            }
            h6 {
              font-size: 20px;
              color: #333333;
            }
          }
          .installment-user {
            width: 100%;
            border-bottom: 1px solid #e0e0e0;
            display: flex;
            gap: 30px;
            @media (max-width: $breakpoint-md) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .user-info {
              display: flex;
              flex-direction: column;
              gap: 10px;
              width: 100%;
              .user-id,
              .user-name {
                width: 100%;
                display: flex;
                align-items: center;
                h6 {
                  width: 50%;
                  margin: 0;
                  font-size: 14px;
                  color: #333333;
                  text-align: start;
                }
                p {
                  width: 50%;
                  margin: 0;
                  font-size: 14px;
                  color: #333333;
                }
              }
            }
          }
          .installment-amount {
            display: flex;
            width: 100%;
            h6 {
              font-size: 16px;
              color: #333333;
              width: 50%;
              text-align: start;
            }
            .installment-value-box {
              display: flex;
              width: 50%;
              flex-direction: column;
              margin-inline-start: auto;
              p {
                margin: 0;
                &.installment-value {
                  font-size: 30px;
                  color: #333333;
                }
                &.installment-currency {
                  font-size: 14px;
                  color: #333333;
                }
              }
            }
          }
        }
        .installment-paid {
          background: #ffffff;
          border-radius: 8px;
          .trx-title,
          .receipt-details {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .trx-title {
            gap: 20px;
            padding: 40px 40px 30px 40px;
            border-bottom: 1px solid #e0e0e0;
            img {
              width: 115px;
              height: 115px;
            }
            h5 {
              font-size: 21px;
              color: #333333;
            }
          }
          .receipt-details {
            padding: 20px 40px;
            .receipt-id,
            .trx-id {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              text-align: start;
              @media (max-width: $breakpoint-phone) {
                flex-direction: column;
              }
              h6 {
                font-size: 14px;
                color: #333333;
                width: 70%;
                @media (max-width: $breakpoint-phone) {
                  width: 100%;
                }
              }
              p {
                font-size: 14px;
                color: #333333;
                width: 30%;
                @media (max-width: $breakpoint-phone) {
                  width: 100%;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
        .no-installments {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 352px;
          padding: 40px;
          background: #ffffff;
          border-radius: 8px;
          img {
            width: 110px;
            height: 110px;
          }
          h5 {
            margin-top: 25px;
            font-size: 18px;
            color: #333333;
          }
          h6 {
            font-size: 18px;
            color: #333333;
          }
          .amnmf-btn {
            max-width: 258px;
          }
        }
        .content-boxes {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          justify-content: center;
          .content-box {
            display: flex;
            flex-direction: column;
            width: 30%;
            padding: 20px;
            background: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 10px;
            color: #15abc1;
            @media (max-width: $breakpoint-md) {
              width: 100%;
              flex-direction: row;
              align-items: center;
              p {
                &:first-child {
                  margin: 0 1rem;
                }
              }
            }
            p {
              text-align: start;
              font-size: 14px;
              &:first-child {
                background: #00b7c6;
                color: #fff;
                padding: 5px 10px;
                border-radius: 15px;
                width: fit-content;
                height: 30px;
              }
            }
          }
        }
        .user-phone {
          display: flex;
          justify-content: center;
          margin-top: 50px;
          .phone-icon {
            padding: 0 20px;
          }
          .user-phone_input {
            max-width: 590px;
            width: 39vw;
            height: 57px;
            background: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 30px;
            align-items: center;
            @media (max-width: $breakpoint-md) {
              width: 100%;
            }
            input {
              border: none;
              padding: 0;
              display: flex;
              text-align: left;
              &:focus {
                box-shadow: none;
                z-index: 0;
              }

              &::placeholder {
                text-align: left;
              }
            }
          }

          .send-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 49px;
            margin-inline: 10px !important;
            padding: 6px 20px;
            background: #f37021;
            box-shadow: 0px 3px 6px #3c3c3c42;
            border-radius: 30px !important;
            border: none;
            inset-inline-end: 5px;
            color: #fff;
            p {
              @media (max-width: $breakpoint-md) {
                display: none;
              }
            }
            img {
              width: 12px;
              height: 12px;
              margin: 0 10px;
            }
          }
        }
        .error-message{
          padding: 20px;
          width: 50%;
          background-color: #f0bdc7;
          color: rgb(162, 19, 19);
          border-radius: 10px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .send-btn {
    img {
      transform: rotate3d(1, 1000, 1, 180deg);
    }
  }

  .user-phone_input {
    input {
      margin-right: 10px !important;
      text-align: right !important;
      &::placeholder {
        text-align: right !important;
      }
    }
  }
}
