%statistic-centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.aboutAman__header__section {
  padding: 0rem 0 6rem;

  @media screen and (max-width: 992px) {
    padding: 0 0rem;
  }

  .aboutAman__header__section__content {
    background: linear-gradient(to right, #0aa0b5 0%, #005b69 120%);
    padding: 0 2.5rem;

    @media (max-width: 425px) {
      padding: 0 1.5rem;
    }

    @media (max-width: 380px) {
      padding: 0 0.8rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    h2 {
      font-size: 1.875rem;

      @media (max-width: 425px) {
        font-size: 1.55rem;
      }
    }

    p {
      font-size: 1.25rem;
    }

    ul {
      li {
        font-size: 1.25rem;
      }
    }

    .nav-anchor-link {
      text-decoration: none;
      color: #fff;
    }
  }

  .aboutAman__header__section__imgs {
    position: relative;
    height: 100%;

    @media screen and (max-width: 992px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img:first-child {
      height: 610px;

      @media screen and (max-width: 992px) {
        height: 300px;
        object-fit: cover;
        width: 100%;
      }
    }

    .img__overlay {
      background: rgba(10, 160, 181, 0.4);
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0 1.6rem;
      height: 100%;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    img:last-child {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }
}

.aboutAman__statistics__section {
  div {
    width: 40%;

    @media screen and (max-width: 992px) {
      width: 250px;
      display: flex !important;
      flex-direction: column;
      margin-top: 20px;
    }

    .aboutAman__statistics__branchesItem {
      position: relative;

      //   img {
      //     position: relative;
      //   }
      .aboutAman__statistics__branches__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;

        p {
          // width: 75%;
          margin: auto;
        }

        @media (max-width: ($breakpoint-md)) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100% !important;
          height: 100%;
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 30%;
          }
        }
      }
    }

    .aboutAman__statistics__clientItem {
      position: relative;

      // left: 7%;
      .aboutAman__statistics__client__content {
        @extend %statistic-centered-content;

        @media (max-width: ($breakpoint-md)) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100% !important;
          height: 100%;
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 30%;
          }
        }
      }
    }
  }
}

.aboutAman__shakingHand__section {
  background-color: #1f94a7;

  .aboutAman__shakingHand__img {
    background-image: url(../../assets/images/img_shaking_hands.svg);
    height: auto;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
  }

  .aboutAman__shakingHand__content {
    padding: 3rem;

    @media (max-width: 425px) {
      padding: 1.5rem;
    }

    h2 {
      width: 80%;
    }

    a {
      background-color: #f37021;
      padding: 0.5rem 2.5rem;
    }
  }
}

.aboutAman__history__section {
  background: #24abc1;
  padding: 12rem 0 12rem 0;
  padding-inline-start: 4rem;
  padding-inline-end: 0;
  overflow: hidden; //* text box width overflows

  @media (min-width: 993px) and (max-width: 1366px) {
    padding: 2rem;
  }

  @media screen and (max-width: 992px) {
    padding-inline-start: 0rem;
    padding: 2rem 0 6rem 0;
  }

  .aboutAman__history__slider {
    position: relative;

    @media (min-width: 993px) and (max-width: 1366px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 992px) {
      //   width: 250px;
      display: flex !important;
      flex-direction: column;
      //   margin-top: 20px;;
    }
  }

  .aboutAman__history__yearDescription {
    background-color: #3cb4c8;
    padding: 6rem 0 3rem 0;
    padding-inline-start: 10rem;
    padding-inline-end: 5rem;

    width: 30%;
    min-height: 280px;

    @media (min-width: 993px) and (max-width: 1366px) {
      width: 100%;
      padding: 2rem;
      order: 2;
      min-height: fit-content;
    }

    @media screen and (max-width: 992px) {
      padding: 2rem;
      order: 2;
      min-height: fit-content;
      width: 90%;
      align-self: center;
      margin-top: 30px;
    }

    p {
      width: 95%;
    }
  }

  .carousel-nav {
    background: none;
    border: none;

    &:focus-visible {
      outline: none;
    }

    svg {
      transform: rotate(180deg);
      margin-top: 7px;

      path {
        fill: rgb(225, 89, 31);
      }
    }
  }

  .history-section__year-svg {
    position: absolute;
    top: -160px;
    inset-inline-start: 10px;
    display: block;
    font-weight: 800;
  }

  .history-carousel {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    width: calc(70% - 265px);

    @media (min-width: 993px) and(max-width: 1366px) {
      width: calc(100% - 290px);

      .owl-item {
        position: relative;
        right: 50px;
      }
    }

    @media (min-width: 768px) and (max-width: 992px) {
      width: calc(100% - 290px);
      align-self: center;

      .owl-item {
        .item {
          span {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 320px;
      align-self: center;

      .owl-item {
        .item {
          span {
            display: none;
          }
        }
      }
    }

    .owl-item {
      height: 145px;

      .item {
        height: 100%;
      }
    }

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.5);
      margin: 1rem 2rem 0;

      &.active {
        color: #fff;
      }

      h4 {
        font-size: 3rem;
      }
    }
  }

  .owl-dots {
    display: none;
  }
}

.aboutAman__vision__section {
  background: #1d8b9e;

  h2 {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }

  .aboutAman__vision__img {
    position: relative;
    background-image: url(../../assets/images/img_aman_store.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    background-position: right;

    @media screen and (max-width: 992px) {
      height: 400px;

      width: 100%;

      .arrow__img {
        display: none;
      }
    }

    .arrow__img {
      position: absolute;
      transform: rotate(180deg);
      left: -20%;
      height: 100%;
    }
  }

  div {
    padding-inline: 7.5rem 3rem;

    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      padding: 3rem !important;
    }

    @media screen and (max-width: 991.98px) {
      //   padding-left: 0rem !important;
      //   padding-right: 0rem !important;
      padding: 2rem !important;
      width: 100%;
    }

    p {
      width: 80%;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
}

.aboutAman__teamMembers__section {
  background-color: #24abc1;
  padding: 0 2rem;

  @media screen and (max-width: 992px) {
    padding: 0 0rem;
    padding-top: 2rem !important;
  }

  .aboutAman__teamMembers__items {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 992px) {
      align-items: center;
      justify-content: center;
      height: 480px;
      gap: 20px;
      width: 100%;
      overflow-y: scroll;
      margin-top: 10px;
      padding-top: 1rem !important;
    }
  }

  .aboutAman__teamMember__item {
    margin-block-end: 16px;
    padding-inline: 8px;

    &__wrapper {
      position: relative;
    }

    img {
      height: 440px;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    &:hover {
      .aboutAman__teamMember__item__content {
        padding: 5rem 1rem;
      }
    }

    .aboutAman__teamMember__item__content {
      background: linear-gradient(transparent 1%, 25%, #046b7c 100%);
      position: absolute;
      border-radius: 0.5rem;
      bottom: 0;
      left: 0;
      padding: 3rem 1rem;
      transition: padding 0.3s ease;
    }
  }
}

html[dir="rtl"] {
  .aboutAman__header__section {
    .img__overlay {
      left: 95%;
    }
  }

  .aboutAman__statistics__section {
    .aboutAman__statistics__branchesItem {
      // .aboutAman__statistics__branches__content {
      //   left: 55%;
      // }
    }

    .aboutAman__statistics__clientItem {
      // left: -7%;
      // .aboutAman__statistics__client__content {
      //   left: 50%;
      // }
    }
  }

  .aboutAman__vision__section {
    div {
      padding-right: 7.5rem;
      padding-left: 3rem;
    }

    .aboutAman__vision__img {
      .arrow__img {
        transform: rotate(0deg);
        right: -20%;
      }
    }
  }

  .carousel-nav {
    transform: rotate3d(1, 1000, 1, 180deg);
  }
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin: auto;
  display: block;
}

.content {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
