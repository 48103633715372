.loan-request-section {
  margin-inline: 10px;

  h1 {
    &.carousel-header {
      position: absolute;
      top: 340px;
      z-index: 77;
      left: 30%;
      right: 30%;
      text-align: center;
      background-color: #f37021;
      color: #fff;
    }
  }

  .owl-carousel {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .item {
            img {
              filter: grayscale(100%) brightness(70%);
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .loan-request-form-container {
    padding: 40px;
    margin: 0 auto 4rem auto;
    max-width: 60%;
    background-color: #fff;
    position: relative;
    top: -80px;
    box-shadow: 1px 4px 50px -1px rgb(0 0 0 / 22%);
    z-index: 77;

    .success-form {
      width: 100%;

      .succes-form-icon-text {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f2f2f2;
        padding: 2rem;

        img {
          margin-bottom: 20px;
        }

        h3 {
          font-family: "HelveticaNeueLTArabic-Bold";
          color: black;
          font-size: 20px;
          line-height: 30px;

          &:last-of-type {
            margin-top: 10px;
          }
        }
      }

      .send-form-button {
        display: flex;
        margin: 1rem auto 0px auto;
        box-shadow: 0px 3px 6px #3c3c3c42;
        border-radius: 32px;
        width: 40%;
        color: #fff;
        background-color: #e1591f;
        font-size: 16px;
        padding: 12px;
        margin-top: 30px;
        font-family: "HelveticaNeueLTArabic-Bold";
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 767.98px) {
          width: 100%;
        }

        + h3 {
          text-align: center;
          font-family: "HelveticaNeueLTArabic-Bold";
          color: black;
          font-size: 16px;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }

    @media (max-width: 991.98px) {
      max-width: 90%;
    }

    .loan-request-form {
      .error-state {
        margin-inline-start: 10px;
        display: flex;
        align-items: center;

        p {
          color: #f27123;
        }

        img {
          margin-top: 7px;
          margin-inline-end: 5px;
        }
      }

      .submit-btn {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-element {
          display: flex;
          margin-inline-start: 5px;
        }
      }

      label {
        color: #212529;
        display: block;
        font-size: 17px;
      }

      select {
        height: 45px;
        font-family: "HelveticaNeueLTArabic-Light";
        color: #333333cc;
        line-height: 1.7;
      }
    }
  }
}

html[dir="rtl"] {
  .loan_request_modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .policy-sec {
            p {
              padding: 10px 10px 0 0;
            }

            .loan_request_btn {
              a {
                &:hover {
                  img {
                    transform: translate(-20px);
                  }
                }

                img {
                  transform: rotate(0deg);
                }
              }
            }

            img {
              float: right;
              margin: 0 0 0 10px;

              &.modal-map-icon {
                margin: 20px 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}

.loan_request_modal {
  .modal-dialog {
    max-width: 950px;
    margin: 1.75rem auto;

    .modal-content {
      height: auto;
      border-radius: 0.3rem;

      @media (max-width: 991.98px) {
        width: 90%;
        margin-inline: auto;
      }

      .modal-body {
        overflow: hidden;

        .policy-sec {
          background-color: #01abc1;
          height: 100%;
          padding: 40px;
          color: #fff;

          @media (max-width: $breakpoint-xs) {
            padding: 40px 15px;
          }

          h2 {
            text-align: center;
            font-size: 20px;
            padding-bottom: 20px;
          }

          h3 {
            line-height: 35px;
            font-size: 24px;
          }

          ol {
            font-size: 17px;
            clear: both;

            li {
              padding: 3px 0;
            }
          }

          img {
            float: left;
            margin-right: 10px;

            &.modal-map-icon {
              margin: 20px 10px 0 0;
            }
          }

          p {
            font-size: 17px;
            padding: 10px 0 0 10px;
          }

          & > button {
            display: block;
            margin: 1rem auto 0px auto;
            width: fit-content;
            color: #fff;
            background-color: #f27022;
            border-color: #f27123;
            font-size: 22px;
          }

          .loan_request_btn {
            margin-top: 40px;

            a {
              font-size: 22px;
              display: flex;

              &:hover {
                color: #fff;

                img {
                  transform: rotate(180deg) translate(-20px);
                }
              }

              img {
                transition: 1s;
                transform: rotate(180deg);
              }
            }
          }

          .bg-line {
            z-index: 97;
            position: relative;

            &:before {
              bottom: 1px;
              height: 10px;
              content: "";
              background-color: #f37021;
              width: 100%;
              bottom: 0px;
              right: 0;
              position: absolute;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .my-col {
    width: 49%;
  }

  .text-start {
    text-align: start;
  }
}
