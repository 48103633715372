$navMainColor: #2fa3b5;
$navSubColor: #1c8697;
$navActiveColor: #197989;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-laptop-sm: 1024px;
$breakpoint-laptop-md: 1200px;
$breakpoint-desktop-md: 1560px;
$breakpoint-desktop-lg: 1940px;

html[dir="rtl"] {
  .dropdown-nav {
    .dropdown-menu {
      right: 0;
    }

    .orange-arrow {
      margin: 0 0.3rem;
      transform: rotate(0deg);
    }

    &.show {
      .orange-arrow {
        transform: rotate(-90deg);
      }
    }
  }
}

.dropdown-nav {
  display: flex;
  position: relative;
  height: 100%;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  .orange-arrow {
    margin: 0 0.3rem;
    transform: rotate(180deg);
  }

  @media (max-width: $breakpoint-desktop-md) {
    padding: 0 0.5rem 7px;

    span {
      display: none;
    }
  }

  @media (max-width: 400px) {
    padding: 0px 4px 7px;
  }

  &.show {
    .dropdown-toggle {
      background: transparent; //$navSubColor;
    }
  }

  .dropdown-toggle {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:after {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  .orange-arrow {
    margin: 0 0.3rem;
    transform: rotate(180deg);
  }

  button {
    border: none;

    &:hover {
      background: $navSubColor;
    }
  }

  img {
    transition: ease-in-out 0.25s;
  }

  &.show {
    .orange-arrow {
      transform: rotate(270deg);
    }
  }

  .dropdown-menu {
    background-color: $navActiveColor;
    box-shadow: 0px 4px 7px #00000027;
    width: 270px;
    transition: 0.4s;

    @media (max-width: 1200px) {
      transition: 0s;
    }

    &.show {
      @media (max-width: $breakpoint-laptop-md) {
        position: absolute;
        top: 75px;
        padding: 8px;
      }

      @media (max-width: 991.98px) {
        position: relative;
        top: 0;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border: 0;
      }
    }

    a {
      padding: 0.8rem 1rem;

      &:hover {
        background-color: $navMainColor;
      }
    }
  }
}
