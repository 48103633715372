.islamic-loan__container {
  width: 91%;
  margin: auto;
  padding: 3rem 0;
  gap: 10px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1110px) {
    flex-direction: column;
    justify-content: center;
  }
}

.islamic-loan_details {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1110px) {
    justify-content: center;
    align-items: center;
  }

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1111px) {
      align-items: flex-start;
    }

    h2 {
      margin-right: 10px;
      font: normal normal bold 25px/68px "HelveticaNeueLTArabic-Bold";
    }

    h4 {
      font: normal normal 300 20px/42px HelveticaNeueLTArabic-Light;
      margin-right: 10px;
      width: 70%;
      text-align: start;

      @media screen and (max-width: 1110px) {
        text-align: center !important;
      }
    }
  }

  img {
    width: 368px;
    height: 314px;

    @media screen and (max-width: 1110px) {
      margin-right: 100px;
    }

    @media screen and (max-width: 520px) {
      margin-right: 50px;
      width: 243px;
      height: 231px;
    }
  }
}

.islamic-loan_modal-box {
  width: 65%;

  background-color: white;
  box-shadow: 0px 20px 40px #15151529;
  border-radius: 10px;
  margin-inline-start: auto;

  @media screen and (max-width: 1110px) {
    width: 90%;
    margin-inline: auto;
  }


  .succes-form-icon-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f2f2f2;
    padding: 2rem;

    img {
      margin-bottom: 20px;
    }

    h3 {
      font-family: "HelveticaNeueLTArabic-Bold";
      color: black;
      font-size: 20px;
      line-height: 30px;

      &:last-of-type {
        margin-top: 10px;
      }
    }

    +button {
      width: 70%;
    }
  }

  .button-container {
    display: flex;
    width: 100%;
    background: #197989 0% 0% no-repeat padding-box;

    .modal-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 70px;
      cursor: pointer;
      font-family: "HelveticaNeueLTArabic-Bold";

      h1 {
        font-size: 1.1rem;
      }
    }
  }

  .islamic-loan__text-description {
    font-family: "HelveticaNeueLTArabic-Bold";
    color: #707070;
    padding: 25px 50px;
    line-height: 30px;

    p {
      font-family: "HelveticaNeueLTArabic-Light";
      font-size: 0.95rem;
    }

    h6 {
      color: #e1591f;
      font-size: 1rem;
      margin-block: 2rem 1rem;
    }

    .apply_loan_button {
      display: block;
      margin: 1rem auto 0px auto;
      box-shadow: 0px 3px 6px #3c3c3c42;
      border-radius: 32px;
      width: 100%;
      color: #fff;
      background-color: #e1591f;
      font-size: 16px;
      padding: 10px;
      margin-top: 30px;
    }
  }
}

.apply-loan-modal {
  width: 100%;
  height: 100%;
  padding: 3rem;

  @media screen and (max-width: 767.98px) {
    padding: 1rem;
  }

  .apply-loan-title {
    font: normal normal bold 17px/68px "HelveticaNeueLTArabic-Bold";
    color: #f37021;
    line-height: 42px;
  }

  .apply-loan-full-name {
    font-family: "HelveticaNeueLTArabic-Bold";
    color: black;
    display: flex;
    align-items: center;
    line-height: 20px;
    gap: 8px;
    font-size: 1rem;
  }

  .form-input {
    font-family: "HelveticaNeueLTArabic-Bold";
    color: black;
    line-height: 6px;
    margin-top: 12px;
    padding: 8px;
    font-size: 0.9rem;
  }
}

.row-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 1110px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.row-plus-info-checkbox {
  display: flex;
  flex-direction: row;
  gap: 50%;
  width: 50%;
  margin-top: 18px;

  .check-box-plus-text {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    cursor: pointer;

    .check-box {
      width: 17px;
      height: 17px;
      border-radius: 30px;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #197989;

      .full-circle {
        width: 12px;
        height: 12px;
        border-radius: 30px;
        background-color: #197989;
      }
    }

    h4 {
      font-family: "HelveticaNeueLTArabic-Bold";
      color: #707070;
      font-size: 0.9rem;
    }
  }
}

.form-select-style,
.form-input {
  font-family: "HelveticaNeueLTArabic-Bold";
  color: #707070;
  line-height: 30px;
  margin-top: 12px;
  width: 100%;
  font-size: 0.9rem;
}

.send-form-button {
  display: flex;
  margin: 1rem auto 0px auto;
  box-shadow: 0px 3px 6px #3c3c3c42;
  border-radius: 32px;
  width: 100%;
  color: #fff;
  background-color: #e1591f;
  font-size: 16px;
  padding: 12px;
  margin-top: 30px;
  font: normal normal bold 13px "HelveticaNeueLTArabic-Bold";
  align-items: center;
  justify-content: center;
}