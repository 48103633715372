$first-break-point: 1449.9px;
$second-break-point: 1100px;

@keyframes rightAnimated {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }

  40% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes leftAnimated {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  40% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes upAnimated {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  40% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes leftHeaderAnimated {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  //  rotate3d(1, 1000, 1, 180deg)
  50% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes leftHeaderAnimatedWithRotation {
  0% {
    opacity: 0;
    transform: rotate3d(1, 1000, 1, 180deg) translateX(-50%);
  }

  50% {
    opacity: 1;
    transform: rotate3d(1, 1000, 1, 180deg) translateX(0%);
  }

  100% {
    opacity: 1;
    transform: rotate3d(1, 1000, 1, 180deg) translateX(0);
  }
}

%right-animation {
  animation-name: rightAnimated;
  animation-duration: 2s;
  animation-fill-mode: both;
}

%left-animation {
  animation-name: leftAnimated;
  animation-duration: 2s;
  animation-fill-mode: both;
}

.contact-us__container {
  width: 91%;
  margin: auto;
  padding: 3.5rem 0;
  gap: 10px;

  @media (max-width: $first-break-point) {
    flex-direction: column;
    gap: 0px;
  }

  @media (max-width: $breakpoint-md) {
    padding-top: 0px;
  }

  .contact-us__header {
    width: 20%;
    margin-top: 5.2rem;
    position: relative;

    @media (max-width: $first-break-point) {
      margin-top: 0rem;
      width: auto;
    }

    p {
      font-size: 1.25rem;
      animation-name: leftHeaderAnimated;
      animation-duration: 3s;
    }

    .contact-us__header__triangle {
      width: 30px;
      transform: rotate(180deg);
      animation-name: leftHeaderAnimated;
      animation-duration: 3s;
    }

    h2 {
      font-size: 45px;
      animation-name: leftHeaderAnimated;
      animation-duration: 3s;
    }

    .contact-us__header__bottomSide {
      .contact-us__header__phone {
        width: 30px;
        transform: rotate(113deg);
        order: 1;
      }

      h3 {
        font-size: 50px;
        order: 2;

        @media (max-width: 500px) {
          font-size: 37px;
        }
      }
    }
  }

  .contact-us__form {
    position: relative;
    color: #212529;
    width: 65%;
    padding: 3rem;

    .form-select {
      line-height: 1.8;
    }

    input,
    select {
      height: 45px;
    }

    @media (max-width: $breakpoint-md) {
      margin-top: 10px;
    }

    @media (max-width: $first-break-point) {
      width: 75%;
    }

    @media (max-width: 1335px) {
      width: 70%;
    }

    @media (max-width: 1235px) {
      width: 65%;
    }

    @media (max-width: 1100px) {
      width: auto;
    }

    &::after {
      content: "";
      width: 100px;
      position: absolute;
      padding: 3rem 2.8rem;
      left: -1.2%;
      z-index: -1;
      bottom: -1.5%;
      background-color: rgb(243, 112, 33);
    }

    form {
      @extend %left-animation;
    }

    .financial__grow-shape__image {
      position: absolute;
      bottom: 14%;
      right: -48%;
      animation-name: leftHeaderAnimatedWithRotation;
      animation-duration: 3s;

      @media (max-width: 1600px) {
        right: -60%;
      }

      @media (max-width: $first-break-point) {
        right: -40%;
      }

      @media (max-width: 1290px) {
        right: -50%;
      }

      @media (max-width: 1100px) {
        display: none;
      }
    }

    .form-message-type {
      width: 45%;

      @media (max-width: $breakpoint-md) {
        width: auto;
      }
    }

    .contact-us__form__row-1 {
      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        gap: 8px;
      }
    }

    .contact-us__form__row {
      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        gap: 8px;
      }
    }

    .contact-us__form__row,
    .contact-us__form__input {
      @media (max-width: $second-break-point) {
        width: 100%;
      }

      // width: 85%;
    }

    .contact-us__form__textarea {
      resize: none;
    }

    .contact-us__btn {
      background-color: rgb(243, 112, 33);
      border: 0;
      margin-inline: auto;
      color: #fff;
    }
  }

  .formGridDateOfBirth {
    @media (max-width: $second-break-point) {
      padding-inline-start: 22px;
    }

    @media (max-width: $breakpoint-md) {
      padding-inline-start: 0px;
    }
  }

  p.animated-para {
    padding-top: 110px;
    position: relative;
    animation-name: upAnimated;
    animation-duration: 2s;
    animation-fill-mode: both;
    transition: 0.3s;

    @media (max-width: $first-break-point) {
      padding-top: 40px;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: rgb(243, 112, 33);
      width: 80px;
      padding: 0.3rem 1rem;
      top: 13%;
      left: -24%;

      @media (max-width: $first-break-point) {
        display: none;
      }
    }
  }
}

html[dir="rtl"] {
  .form-message-type {
    width: 25%;
  }

  .contact-us__container {
    .contact-us__header {
      width: 20%;

      @media (max-width: $first-break-point) {
        width: auto;
      }

      p {
        @extend %financial-shape-animation;
      }

      .contact-us__header__triangle {
        transform: rotate(0deg);
        @extend %financial-shape-animation;
      }

      h2 {
        @extend %financial-shape-animation;
      }

      .contact-us__header__bottomSide {
        .contact-us__header__phone {
          order: 2;
        }

        h3 {
          order: 0;
        }
      }
    }

    .contact-us__form {
      form {
        @extend %right-animation;
      }

      &::after {
        right: -1.2%;
        width: 100px;
        left: 0;
      }
    }

    p.animated-para {
      &::before {
        right: -24%;
      }
    }

    .financial__grow-shape__image {
      inset-inline-start: 95%;
      transform: scale(0.85);

      @extend %financial-shape-animation;

      @media (max-width: $first-break-point) {
        right: 92%;
      }
    }
  }
}
