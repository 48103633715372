$breakpoint-phone: 440px;
$breakpoint-xs: 575.98px;
$breakpoint-sm: 767.98px;
$breakpoint-md: 991.98px;
$breakpoint-lg: 1199.98px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1940px;

$navMainColor: #2fa3b5;
$navSubColor: #1c8697;
$navActiveColor: #197989;
