$padding: 10%;
$padding-sm: 6%;

.nano-lending-wrapper {
  .nano-hero-section {
    justify-content: space-between;

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-inline-start: $padding;

      @media screen and (max-width: 991.98px) {
        padding: $padding $padding-sm;
      }

      .bg-text {
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 9px;
        margin-block-start: 1rem;

        p {
          margin: 1.3rem;
        }
      }
    }

    &__left {
      display: flex;
      justify-content: flex-end;

      &:lang(en) {
        justify-content: flex-start;
      }

      img {
        width: 100%;

        @media screen and (min-width: 768px) and (max-width: 991.98px) {
          width: 80%;
        }
      }

      @media screen and (max-width: 767.98px) {
        transform: translateX(-50px);
      }
    }
  }

  .nano-app-download {
    padding: $padding;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 991.98px) {
      padding: $padding $padding-sm;
    }

    &__left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        &:first-of-type {
          width: 88%;
        }

        &:last-of-type {
          position: absolute;
          inset-block-end: -399px;
          inset-inline-start: -68%;

          // transform: translateY(100px);
          width: 100%;

          @media screen and (max-width: 991.98px) {
            display: none;
          }

          @media screen and (min-width: 992px) and (max-width: 1199.98px) {
            inset-block-end: -299px;
          }
        }
      }
    }

    .amanApp__advertise__appStore {
      display: flex;
      margin-block: 2rem;

      img {
        width: 180px;
      }

      @media (max-width: ($breakpoint-tablet)) {
        text-align: center;
      }

      @media (max-width: 519.98px) {
        flex-direction: column;
      }
    }
  }

  p {
    margin-block: 1.5rem;
    font-size: 1.2rem;
    color: white;

    @media screen and (max-width: 767.98px) {
      font-size: 1rem;
    }
  }

  .bg-span {
    background-color: #f37021;
    line-height: 12px;
  }

  .faq-container {
    background-color: #139aab;
    padding: $padding;
    position: relative;

    @media screen and (max-width: 991.98px) {
      padding: $padding $padding-sm;
    }

    img {
      position: absolute;
      inset-inline-end: 0;
      inset-block-end: 100px;
      transform: scaleX(-1);

      @media screen and (max-width: 767.98px) {
        display: none;
      }

      &:lang(ar) {
        transform: none;
      }
    }

    .faq-box {
      width: auto;
      margin-block-start: 65px;

      .shift-down .circleIcons-row .kite-img {
        transform: rotate(32deg);
      }
    }

    .faq-tabs-container {
      margin: 0;
      border-radius: 10px;

      .tab-content {
        height: 250px !important;

        ul {
          margin-block-start: 1.8rem;

          li {
            position: relative;
            margin-inline-start: 2rem;
            margin-block: 1rem;

            &:before {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: #333333;
              border-radius: 50%;
              inset-inline-start: -30px;
              inset-block-start: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      h2 {
        font-size: 18px;
        position: relative;
        width: fit-content;
        z-index: 1;

        color: #333333 !important;

        &::after {
          position: absolute;
          width: 100%;
          height: 10px;
          background-color: #f37021;
          content: "";
          inset-inline-start: 0;
          inset-block-end: -7px;
          z-index: -1;
        }
      }
    }

    .faq-tabs {
      li {
        button {
          background: #0f7989;

          &:hover {
            background: #f37021;
            color: #fff !important;
          }
        }
      }
    }
  }
}
