$breakpoint-tablet: 768px;

html[dir="rtl"] {
  .services-container {
    .services-header {
      .right-section-header {
        a {
          &.s18 {
            margin-right: 30px;
            margin-left: 0;
          }
        }
      }
    }

    a {
      &:hover {
        img {
          transform: translateX(-20px);
        }
      }

      img {
        transform: rotate(0deg);
      }
    }

    .circles-row {
      .circle-icon-container {
        right: 0;
      }
    }

    .circle-icon-container {
      right: -5vw;

      .circle-icon {
        .kite-img {
          inset: 0;
        }
      }

      @media (max-width: $breakpoint-tablet) {
        right: 0vw;
      }

      &.reader {
        right: -25vw;

        img {
          transform: rotate3d(0, 0, 0, 0);
        }
      }
    }
  }

  .left-row {
    .circle-icon-container {
      .circle-icon {
        inset: 0 -30px;

        @media (max-width: $breakpoint-tablet) {
          inset: 0;
        }

        .kite-img {
          inset: 0;
        }
      }
    }
  }
}

.services-container {
  color: #fff;
  overflow: hidden;

  .row .col {
    padding: 0 15px;
  }

  h1 {
    &.s45 {
      font-size: 28px;
      line-height: 40px;
      margin: 200px 30px 8px 30px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 20px;
        line-height: 30px;
        margin: 140px 10px 8px 10px;
      }
    }
  }

  h2 {
    text-align: center;
    font-size: 30px;
    padding: 10px 25px 25px 25px;

    &.orangeBg {
      width: fit-content;
      margin: 0 40px 8px 40px;
      background: #f47005;

      @media (max-width: $breakpoint-tablet) {
        font-size: 18px;
        padding: 15px;
        margin: 8px 10px;
      }

      &.w100 {
        width: 100%;
      }
    }
  }

  h3 {
    font-size: 1.75rem;

    &.s24 {
      font-size: 18px;
      line-height: 30px;

      transform: translateX(32px);

      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
        line-height: 18px;
        margin-right: 10px;
        transform: none;
      }
    }

    &.s25 {
      font-size: 23px;
      text-align: center;
      line-height: 40px;

      @media (max-width: $breakpoint-tablet) {
        line-height: 20px;
        font-size: 12px;
      }
    }

    &.s45 {
      font-size: 26px;

      @media (max-width: $breakpoint-tablet) {
        padding-top: 17px;
        font-size: 14px;
      }
    }

    &.s75 {
      @media (max-width: $breakpoint-tablet) {
        font-size: 18px;
        line-height: 0.5;
      }
    }
  }

  h4 {
    font-size: 1.5rem;

    &.s21 {
      font-size: 16px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 9px;
      }
    }

    &.s31 {
      font-size: 21px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
    }
  }

  p {
    margin: 20px 40px 16px 40px;
    width: 60%;

    &.s23 {
      font-size: 20px;
      width: 80%;
      margin: 0 0 16px 0;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      width: 90%;
      font-size: 14px;
      margin: 10px;
    }
  }

  a {
    font-size: 18px;

    &:hover {
      color: #fff;

      img {
        transform: translateX(20px) rotate(180deg);
      }
    }

    img {
      transform: rotate(180deg);
      transition: 1s;
    }
  }

  .services-header {
    .right-section-header {
      @media (max-width: $breakpoint-tablet) {
        z-index: 10;
      }

      .arrow {
        margin: 20px 50px;

        a {
          &.s18 {
            margin: 0;
          }
        }
      }

      a {
        &.s18 {
          margin-left: 30px;
          margin-right: 0;

          @media (max-width: $breakpoint-tablet) {
            width: 100%;
            margin: 20px 10px;
            font-size: 14px;
          }
        }
      }
    }

    .services-img {
      background-image: url(../../assets/images/servicesMain.jpg);
      padding-right: 0;
      background-position: center;
      background-size: cover;
      height: 100%;

      @media (max-width: $breakpoint-tablet) {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        height: 100vh;
        filter: grayscale(100%) brightness(50%);
        margin-top: 3rem;
      }
    }
  }

  .bright-blue {
    background-color: #01abc1;
    height: 100%;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }

  .r-h100 {
    height: 90vh;
    min-height: 700px;
  }

  .r-h10 {
    height: 15vh;

    @media (max-width: $breakpoint-tablet) {
      height: 5vh;
    }
  }

  .left-space {
    position: relative;
    background: #148391;

    @media (max-width: $breakpoint-tablet) {
      background-color: #01abc1;
    }
  }

  .right-space {
    position: relative;
    height: 100%;
  }

  .left-row {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      margin: 10px 0;
      gap: 1rem;
    }
  }

  .left-height {
    background: #148391;
    display: flex;
    justify-content: space-between;
    position: relative;

    &.h200 {
      height: 65vw;
      flex-direction: column;
    }

    &.h60 {
      height: 35vw;

      &.circles-2nd {
        height: 60vw;
        flex-direction: column;

        @media (max-width: $breakpoint-tablet) {
          display: flex;
          flex-direction: row;
        }

        .left-row {
          @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
          }
        }
      }

      @media (max-width: $breakpoint-tablet) {
        height: 100%;
        display: unset;
      }
    }

    &.reader-container {
      flex-direction: column;
    }

    .circles-3rd {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: row;
        justify-content: space-around;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .icon-text {
        text-align: center;
        width: 12vw;
        height: 12vw;
        top: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $breakpoint-tablet) {
          width: 65px;
          height: 65px;
        }
      }
    }

    .left-text-with-icon {
      display: flex;
      flex-direction: column;

      img {
        width: 100px;
        transform: translateX(32px);

        @media (max-width: $breakpoint-tablet) {
          width: 0px;
          height: 0px;
          transform: none;
        }

        + p {
          transform: translateX(32px);
        }
      }
    }
  }

  .circle-icon-container {
    position: relative;
    z-index: 99;
    right: 7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    // top: -60px;

    @media (max-width: $breakpoint-tablet) {
      right: 0;
    }

    &.reader {
      right: 25vw;

      img {
        width: 45vw;
        height: 33vw;
        transform: rotate3d(1, 1000, 1, 180deg);

        @media (max-width: $breakpoint-tablet) {
          width: 0;
          height: 0;
        }
      }
    }

    .icon-text {
      width: 85%;
    }

    .circle-icon {
      position: relative;
      border-radius: 3000px;
      width: 12.2vw;
      height: 12.2vw;
      background-color: #01abc1;
      z-index: -1;

      @media (max-width: $breakpoint-tablet) {
        width: 66px;
        height: 66px;
      }

      .kite-img {
        position: relative;
        inset: 0 -0.75vw;
        width: 13vw;
        height: 12.2vw;

        @media (max-width: $breakpoint-tablet) {
          width: 70px;
          height: 65px;
          inset: 0 -4px;
        }
      }

      .icon-img {
        width: 7vw;
        height: 7vw;
        position: absolute;
        top: 2vw;
        left: 2.5vw;

        @media (max-width: $breakpoint-tablet) {
          width: 35px;
          height: 35px;
          top: 15px;
          right: 15px;
        }
      }
    }
  }

  .single-row {
    align-items: center;

    .right-section2 {
      display: flex;
    }

    .left-section {
      .left-text-with-icon {
        display: flex;
        flex-direction: column;

        img {
          @media (max-width: $breakpoint-tablet) {
            width: 0;
            height: 0;
          }
        }
      }
    }

    a {
      text-align: center;
      font-size: 20px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  .circles-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #148391;
    height: 70vh;

    @media (max-width: $breakpoint-tablet) {
      height: 270px;
    }

    .circle-icon-container {
      inset: 0;
      width: 200px;
      height: 300px;

      &.circles-row__adjusted {
        top: -60px;

        @media (max-width: $breakpoint-tablet) {
          top: 0px;
        }
      }

      @media (max-width: $breakpoint-tablet) {
        width: 100px;
        height: 195px;
      }
    }
  }

  .seprator-line {
    &:before {
      background: #f37021;
      height: 100%;
      width: 20px;
      content: "";
      position: absolute;
      inset: 0px -10px;
      z-index: 0;

      @media (max-width: $breakpoint-tablet) {
        content: none;
      }
    }
  }
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(0.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}
