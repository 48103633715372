.privacy__section {
  padding: 0 2rem;
  width: 97%;
  margin: auto;
  color: #212529;

  h2 {
    font-family: "HelveticaNeueLTArabic-bold" !important;
    margin-block: 16px;
  }

  p,
  div {
    font-size: 1rem;
    font-family: "HelveticaNeueLTArabic-light";
  }
}
