.smes-repayment {
  display: flex;
  align-items: center;
  justify-content: center;

  .sme-card {
    width: 50%;
    background-color: white;
    margin-block: 20px;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      width: 98%;
    }

    .step-1 {
      .sme-header {
        background-color: white;
        padding: 20px;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          // justify-content: center;
          h2 {
            color: black;
            font-size: 28px;
            margin-inline: 10px;
            margin-bottom: 0px;
          }
          img {
            transform: translateY(5px);
          }
        }
        p {
          color: #484848;
          font-size: 18px;
          font-weight: 400;
        }
      }
      .sme-body {
        padding: 20px;
        background-color: #f7f7f7;

        p {
          color: #212529;
          font-size: 24px;
          font-weight: 600;
        }

        .info-card {
          background-color: white;
          padding: 10px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          width: 90%;
          margin-bottom: 20px;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            width: 99%;
            align-items: flex-start;
          }

          .img-container {
            min-width: 10%;
          }

          .label {
            font-size: 16px;
            min-width: 30%;
          }
          .value {
            font-size: 16px;
          }
        }

        .my-row {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            width: 99%;
            align-items: flex-start;
            gap: 2rem;
          }

          input {
            width: 90%;
            padding: 8px 16px;
            background: white;
            border: 1px solid;
            border-radius: 10px;
          }
          button {
            width: 40%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e76523;
            color: white;
            border-radius: 24px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .step-2 {
      .sme-header {
        background-color: white;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          color: #484848;
          font-size: 24px;
          font-weight: 400;
        }

        .back-button {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin-inline: 5px;
          }
          p {
            font-size: 16px;
            transform: translateY(-3px);
          }
        }
      }
      .sme-body {
        padding: 20px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #212529;
        width: 100%;

        .input-container {
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          #upload {
            display: none;
          }
        }

        label {
          margin-bottom: 10px;
        }

        .input {
          padding: 8px 16px;
          border-radius: 4px;
          background-color: white;
          border: 1px solid #c1c1c1;
        }

        .file-input-placeholder {
          background-color: white;
          border: 1px dashed #5c5c5c;
          border-radius: 12px;
          width: 100%;
          height: 95px;
          margin-top: 10px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .file-input-result {
          border-radius: 10px;
          border: 1px solid #ebebeb;
          background: #fbfbfb;
          padding: 18px 24px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-block: 10px;

          .my-row {
            display: flex;
            align-items: center;
          }
          .action-btn {
            width: 35px;
            height: 35px;
            border-radius: 24px;
            border: 1px solid #dadada;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
              margin-right: 5px;
            }
          }
        }
        .submit-btn {
          background-color: #e76523;
          border-radius: 15px;
          color: white;
          width: 90%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          &:disabled {
            opacity: 50%;
          }
        }
      }
    }
    .step-3 {
      padding: 20px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #212529;

      .success-image {
        width: 300px;
        object-fit: contain;
      }
    }
  }

  .title-container {
    margin-bottom: 30px;
  }
  .orange-line {
    width: 59px;
    height: 6px;
    background-color: #e76523;
    margin-top: 5px;
  }
}
