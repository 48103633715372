html {
  font-size: 16px;

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }
}

* {
  margin: 0;
  font-family: inherit;
}

body {
  background-color: #01abc1;
  color: #212529;
}

p,
span {
  margin-bottom: 0rem;
  font-family: "HelveticaNeueLTArabic-Bold";
}

a {
  color: #fff;
  text-decoration: auto;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.required-border {
  border-color: #f27123;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $navMainColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $navActiveColor;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $navActiveColor;
}

.error-state {
  margin-inline-start: 10px;
  display: flex;
  align-items: center;

  p {
    color: #f27123;
  }

  img {
    margin-top: 7px;
    margin-inline-end: 5px;
  }
}

.form-control {
  font-family: HelveticaNeueLTArabic-Light;
}
