@import "../../style/abstract/variables";

.news-header__section {
  height: 500px;
  .news-header__header {
    height: 100%;
    padding: 1rem 5rem;
    background: transparent
      linear-gradient(91deg, #0aa0b5 0%, #005b69 100%, #005b69 100%) 0% 0%
      no-repeat padding-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: ($breakpoint-md)) {
      order: 2;
      height: 50%;
      padding: 0.3rem 1.5rem;
    }
    h2 {
      padding-block: 1.5rem;
      @media (max-width: ($breakpoint-md)) {
        padding: 0;
      }
    }
    p {
      overflow: hidden;
      @include remove-more-than(3);
    }
  }
  .banner-image-container {
    height: 100%;
    padding: 0;
    @media (max-width: ($breakpoint-md)) {
      order: 1;
      height: 50%;
    }
    .banner-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
.latest-news__section {
  padding: 3rem 0;
  width: 80%;
  margin: auto;
  .latest-news__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    h2 {
      width: fit-content;
      margin: 0;
      padding-block: 1rem;
      padding-inline: 0;
    }
    .news-select-container {
      position: relative;
      align-self: flex-end;
      padding: 0;
      width: fit-content;
      select::-ms-expand {
        display: none;
      }
      .news-select {
        appearance: none;
        width: 200px;
        background-color: #197989;
        padding: 0.8rem;
        border: 1px solid #0c8fa3;
        border-radius: 4px;
        color: white;
        // .news-select__option {
        //   background-color: #197989;
        //   width: 200px;
        // }
      }
      .news-select:focus {
        outline: none;
      }
      .news-dropdown-icon {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 20px;
      }
      .news-dropdown-icon-left {
        left: 20px;
      }
      .news-dropdown-icon-right {
        right: 20px;
      }
    }
  }
  .flippedIcon {
    transform: scaleX(-1);
    width: 10px;
  }
  .paginate__arrows {
    width: 10px;
  }
  .active-page {
    background-color: #005b69;
    text-align: center;
    border-radius: 14px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
  }
  .pagination__atag {
    color: white;
    margin: 0 1rem;
  }
  .pagination__litag {
    font-size: 12px;
    margin: 0 0.5rem;
  }
  .paginate-line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .pagination-page__atag {
    color: white;
    margin: 0 0.5rem;
  }
}
.empty-news__section {
  background-color: #15abc1;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-news-content {
    margin: auto;
    text-align: center;
  }
}
