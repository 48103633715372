@import "../abstract/variables";
$width-of-border: 10px;
$height-of-border: 90px;

.faq-container {
  background-color: #2fa3b5;
  color: #fff;
  padding: 4rem;
  padding-top: 6rem;

  @media (max-width: $breakpoint-md) {
    padding-top: 3rem;
  }

  @media (max-width: $breakpoint-sm) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }

  @media (max-width: $breakpoint-phone) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .faq-side {
    margin-bottom: 6rem;
    margin-block-start: 6px;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      margin-block-start: 0;
    }

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      margin-bottom: 2rem;
    }

    p {
      padding-top: 1rem;
    }
  }

  .faq-box {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    position: relative;
    width: 1300px;

    @media (max-width: $breakpoint-xxl) {
      width: 67vw;
    }

    @media (max-width: $breakpoint-md) {
      width: 90vw;
      margin-top: 2rem;
    }

    &:before {
      content: "";
      position: absolute;
      width: $width-of-border;
      height: $height-of-border;
      bottom: -$width-of-border;
      left: 3px;
      background-color: #f37021;
    }

    &:after {
      content: "";
      position: absolute;
      width: $height-of-border;
      height: $width-of-border;
      bottom: -$width-of-border;
      left: 3px;
      background-color: #f37021;
    }

    .kiteCircle-row {
      position: relative;
    }

    .shift-down {
      position: relative;
      top: 50px;

      @media (max-width: $breakpoint-sm) {
        display: none;
      }

      .circleIcons-row {
        display: flex;
        position: absolute;
        width: 100%;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        z-index: 98;
        height: 0;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;

        @media (max-width: $breakpoint-md) {
          display: none;
        }

        .circle-icon {
          position: relative;
          border-radius: 3000px;
          width: 120px;
          height: 120px;
          background-color: #01abc1;
          z-index: -1;
        }

        .kite-img {
          width: 125px;
          height: 120px;
          position: absolute;
          bottom: 2px;
          left: -4px;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        .icon-img {
          width: 60px;
          height: 60px;
          position: absolute;
          inset-block-start: 50%;
          transform: translateY(-50%) translateX(50%);

          &:lang(en) {
            transform: translateY(-50%) translateX(-50%);
          }

          inset-inline-start: 50%;
        }

        img {
          vertical-align: middle;
          border-style: none;
        }
      }
    }

    .kiteCircle-row {
      left: 165px;
    }
  }

  .faq-tabs-container {
    background-color: #fff;
    color: #000;
    padding: 0;
    border-start-start-radius: 10px;
    border-start-end-radius: 10px;

    &.container {
      max-width: 100%;
    }

    @media (max-width: $breakpoint-md) {
      max-width: 100%;
    }

    .tab-content {
      padding: 30px;
      height: 350px;
      overflow: auto;

      @media (max-width: $breakpoint-xs) {
        height: 550px;
      }

      @media (max-width: $breakpoint-phone) {
        padding: 30px 15px;
      }
    }

    h2 {
      color: #f37021;
      font-size: 18px;
    }
  }

  .orangeTrin {
    margin: 0.5rem 0;
    transform: rotate(180deg);
  }

  .faq-tabs {
    border-start-start-radius: 10px;
    border-start-end-radius: 10px;

    overflow: hidden;

    li {
      display: flex;
      flex: 1;

      button {
        background: #58595b;
        color: #fff;
        flex: 1;
        padding: 25px 0;
        height: 120px;
        border: none;
        border-radius: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media screen and (max-width: 991.98px) {
          align-items: center;
        }

        @media (max-width: $breakpoint-phone) {
          font-size: 12px;
        }

        &.active {
          background: #f37021 !important;
          color: #fff;

          &:hover {
            background: #f37021 !important;
            color: #fff;
          }
        }

        &:hover {
          background: #fff;
          color: #000 !important;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .orangeTrin {
    transform: rotate(0deg);
  }

  .faq-box {
    &:before {
      right: 3px;
      left: auto;
    }

    &:after {
      right: 3px;
      left: auto;
    }
  }
}
